import { Link } from 'gatsby';
import React from 'react';
import { MdLocationOn } from 'react-icons/md';

import {
  Citizenship,
  Description,
  Employment,
  Location,
  LocIcon,
  ReadMore,
  Requirements,
  Title,
  TitleContainer,
  Wrapper,
} from './Position.styled';

interface Props {
  title: string;
  location: string[];
  employment: string[];
  citizenship: string[];
  requirements?: string;
  description: string;
  href?: string;
}

/**
 * Displays a career position
 *
 * @param {string} title Title of the career position
 * @param {string[]} location
 * @param {string[]} employment Describe the type of employment it is (Full time or Part Time)
 * @param {string[]} citizenship Describe the citizenship requirements for this position
 * @param {string} requirements Some prerequisites for the employment
 * @param {string} description
 * @param {string} href Link to the page that offers more detail about this position
 */
function Position({
  title,
  location,
  employment,
  citizenship,
  requirements,
  description,
  href,
}: Props): JSX.Element {
  const positionElm = (
    <Wrapper data-cy="position-container">
      <TitleContainer>
        <Title>{title}</Title>
        <Location>
          <LocIcon>
            <MdLocationOn />
          </LocIcon>
          {location.join(' / ')}
        </Location>
      </TitleContainer>
      <Employment>{employment.join('/')}</Employment>
      <Citizenship>{citizenship}.</Citizenship>
      {requirements ? <Requirements>{requirements}</Requirements> : null}
      <Description>{description}</Description>
      <ReadMore>Read more ...</ReadMore>
    </Wrapper>
  );

  if (href)
    return (
      <Link to={href} style={{ display: 'block', textDecoration: 'none' }}>
        {positionElm}
      </Link>
    );
  return positionElm;
}

export default Position;
