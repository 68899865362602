import styled from 'styled-components';

export const Wrapper = styled.div`
  @media only screen and (min-width: 768px) {
    border-left: 0.5rem solid ${({ theme }) => theme.primary500};
    padding-left: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;

  @media only screen and (min-width: 1024px) {
    flex-flow: row;
    justify-content: space-between;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.white};

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.25rem;

  margin-left: -1.5px;

  @media only screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Requirements = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 1.125rem;

  letter-spacing: 0.5px;
  line-height: 1.5rem;

  margin-bottom: 1rem;
`;

export const LocIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${({ theme }) => theme.grey300};
  border-radius: 100%;

  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;

  margin-right: 0.5rem;

  @media only screen and (min-width: 768px) {
    height: 24px;
    min-height: 24px;
    width: 24px;
    min-width: 24px;
  }
`;

export const Location = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};

  display: inline-flex;
  align-items: center;
  margin-block: 1rem;

  @media only screen and (min-width: 1024px) {
    justify-content: center;
    margin-block: 0;
  }
`;

export const Citizenship = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 1.125rem;

  letter-spacing: 0.5px;
  line-height: 1.5rem;

  margin-bottom: 1rem;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.grey200};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 1.125rem;

  letter-spacing: 0.5px;
  line-height: 1.25rem;

  margin-bottom: 1rem;
`;

export const Employment = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const ReadMore = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 1.125rem;
  font-weight: bold;
`;
