import { graphql, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import Container from 'src/Components/ContentContainer';
import Position from 'src/Components/Position';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import styled from 'styled-components';

const CareersList = styled.div`
  display: grid;
  row-gap: 3rem;

  a:nth-child(n + 2)::before {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.grey700};
    height: 2px;
    margin-bottom: 3rem;
  }
`;

const LocationFilter = styled.div`
  display: grid;
  margin-bottom: 1.5rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

interface Props {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          parent: {
            name: string;
            relativeDirectory: string;
          };
          frontmatter: {
            title: string;
            job_location: string[];
            employment: string[];
            citizenship: string[];
            requirements: string;
            description: string;
          };
        };
      }>;
    };
  };
}

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  return (
    <Base.Paragraph style={{ marginBottom: '0.5rem' }}>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          style={{ marginRight: '0.5rem' }}
        />
        {label}
      </label>
    </Base.Paragraph>
  );
};

const Careers: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { allMarkdownRemark } = data;
  const { edges: positions } = allMarkdownRemark;

  const locations = [
    'Sydney, NSW, Australia',
    'Adelaide, SA, Australia',
    'Colorado Springs, CO, USA',
    'Boulder, CO, USA',
    'Aurora, CO, USA',
    'Remote',
  ];

  // Array of bools signifying if each location should be displayed (mask)
  const [locationMask, setLocationMask] = useState(
    new Array(locations.length).fill(true),
  );

  // Update the mask when checkboxes are toggled
  const handleChange = (position: number) => {
    const newLocationMask = locationMask.map((item, idx) =>
      idx === position ? !item : item,
    );

    setLocationMask(newLocationMask);
  };

  // Array of locations that should be shown
  const [visibleLocations, setVisibleLocations] = useState(locations);

  // When the mask is updated, update the location filter
  useEffect(() => {
    const newVisibleLocations = locations.filter(({}, idx) => {
      return locationMask[idx];
    });
    setVisibleLocations(newVisibleLocations);
  }, locationMask);

  return (
    <>
      <SEO
        title="Careers | Saber Astronautics"
        description="Working with Saber is an exciting career of first steps for humanity. We look for passion, innovation and creativity. We provide flexibility and a great workplace culture."
        url="https://www.saberastro.com/careers"
      />
      <Layout>
        <Container.Dark data-cy="careers">
          <Base.MenuGap />
          <Base.Text>
            <StaticImage src="../images/careers.jpg" alt="Career showcase" />
            <Base.Gap />
            <Base.Heading>CAREERS</Base.Heading>
            <Base.Paragraph>
              We’re always looking for talent. If you have a passion for space
              and are innovative, creative and independent we want to hear from
              you.
            </Base.Paragraph>
            <Base.Paragraph>
              We look for:
              <ul>
                <li>Engineers, thinkers, tinkerers and builders</li>
                <li>People who are passionate about space</li>
                <li>People who are not afraid to make mistakes</li>
              </ul>
            </Base.Paragraph>
            <Base.Paragraph>Why Work at Saber</Base.Paragraph>
            <Base.Paragraph>
              Saber is a diverse and inclusive workplace full of passionate,
              creative and dedicated people. Here’s a few things our employees
              love about Saber, we offer:
              <ul>
                <li>challenging yet meaningful work</li>
                <li>flexible working hours supporting work-life balance</li>
                <li>a flat working hierarchy</li>
                <li>a culture of innovation</li>
                <li>
                  the latest and greatest in technology, processes and practices
                </li>
                <li>
                  supported opportunities to explore and learn resulting in
                  personal growth and career development
                </li>
              </ul>
            </Base.Paragraph>
            <Base.Paragraph>
              Diversity drives innovation. Saber is an equal opportunity
              employer, we recognise the value of a diverse workforce in
              bolstering ideas, innovation and culture within Saber
              Astronautics’ global workforce. Saber is fully committed to a
              workplace culture that is respectful, inclusive, and diverse. We
              look to foster a sense of belonging and authenticity in our
              employees. Saber aims to set the standard for diverse, inclusive
              and equitable working environments - for ourselves, our customers,
              our partners, our contractors and our community.
            </Base.Paragraph>
            <Base.Gap />
            <Base.Header>
              <Base.Yellow>Open Positions</Base.Yellow>
            </Base.Header>
            <LocationFilter>
              {locations.map((location, index) => {
                return (
                  <Checkbox
                    key={location}
                    label={location}
                    checked={locationMask[index]}
                    onChange={() => handleChange(index)}
                  />
                );
              })}
            </LocationFilter>
            <CareersList>
              {positions.length ? (
                positions.map((position, index) => {
                  const {
                    title,
                    employment,
                    citizenship,
                    requirements,
                    job_location,
                    description,
                  } = position.node.frontmatter;

                  const { name, relativeDirectory } = position.node.parent;

                  if (
                    job_location.some((r) => visibleLocations.indexOf(r) >= 0)
                  ) {
                    return (
                      <Position
                        key={index}
                        href={`/${relativeDirectory}/${name}`}
                        title={title}
                        employment={employment}
                        citizenship={citizenship}
                        requirements={requirements}
                        location={job_location}
                        description={description}
                      />
                    );
                  }
                })
              ) : (
                <Base.Paragraph>
                  There are currently no open positions
                </Base.Paragraph>
              )}
            </CareersList>
          </Base.Text>
        </Container.Dark>
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query CareersQuery {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "positions" } } }
          sort: { fields: frontmatter___title, order: ASC }
        ) {
          edges {
            node {
              parent {
                ... on File {
                  name
                  relativeDirectory
                }
              }
              frontmatter {
                title
                employment
                description
                job_location
                citizenship
                requirements
              }
            }
          }
        }
      }
    `}
    render={(data) => <Careers data={data} />}
  />
);
